import axios from "axios";
import avatar from "../assets/avatar.jpg";

const BASE_URL0 = "https://youtube138.p.rapidapi.com";
//const BASE_URL = "http://localhost:5000/api";
//export const BASE_URL = "http://127.0.0.1:5001/ydaw-7a05a/us-central1/widgets/api";
export const BASE_URL = "https://us-central1-ydaw-7a05a.cloudfunctions.net/widgets/api";

const options0 = {
  params: { hl: "ro", gl: "RO" },
  headers: {
    "X-RapidAPI-Key": "8100be2bc0msh21b20f3f1afc19fp166589jsn7d9c4ce2a773",
    "X-RapidAPI-Host": "youtube138.p.rapidapi.com",
  },
};

let email = localStorage.getItem("etube");

const options = {
  params: { hl: "en", gl: "US", email: email },
};

export const getauth = async (url) => {
  return await axios.get(`${url}`);
}

export const fetchDataFromApi = async (url) => {

if (url.indexOf('related-contents') >= 0 && false){
  const { data } = await axios.get(`${BASE_URL0}/${url}`, options0);
  return data;
}
else {
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
  if (data && !data['title']){
    data.forEach(element => {
      element['type'] = 'video';
      element['video'] = element['short'];
      element['short']['publishedTimeText']=element['short']['publishedTimeText']?element['short']['publishedTimeText'].split('T')[0]:''
      element['video']['thumbnails'] = [{url:element['short']['thumbnail']}];
      element['video']['author'] = {title: element['all']['item']['snippet']['channelTitle'], avatar: [{url: avatar}], badges: [{type:''}]};
    });
    return {contents: data};
  }else{
    data['type'] = 'video';
    data['video'] = data;
    data['publishedTimeText']=data['publishedTimeText']?data['publishedTimeText'].split('T')[0]:''
    data['video']['thumbnails'] = [{url:data['thumbnail']}];
    data['video']['author'] = {title: '', avatar: [{url: avatar}], badges: [{type:''}]};
    data['video']['stats'] = {likes:data['statistics']['likeCount'], views:data['statistics']['viewCount']}
    return data;
  }
}

};
