import React, { useContext, useEffect,useState } from "react";

import { DataContext } from "../context/contextApi";
import LeftNav from "../components/LeftNav";
import VideoCard from "../components/VideoCard";
import UseOnline from "../utils/UseOnline";
import OfflineComponent from "../shared/OfflineComponent";
import ShimmerVideoCard from "../shared/ShimmerVideoCard";
import * as api from "../utils/api";

//import axios from "axios";
import { getauth } from "../utils/api";
// import { db } from "../utils/firebase";
// import { collection, getDocs, addDoc } from "firebase/firestore";

const Feed = () => {
  const { loading, searchResults } = useContext(DataContext);
  const [auth, setAuth] = useState();
  const  [hasKey, setHasKey] = useState(0);

  const isOnline = UseOnline();

  let lll = true;
  let email = ''; let etubekey = '';

  const getauthapi = async () => {
    const data = await getauth(api.BASE_URL+`/../auth`);
    setAuth(data);
  };

  useEffect(() => {
    document.getElementById("root").classList.remove("custom-h");

    async function fetchData() {
      let href = window.location.href
      if (href.indexOf('?') >= 0 && href.split('?').length > 1)
      href = href.split('?')[1]
      if (href.indexOf('=') >= 0 && href.split('=').length > 1){
        href = href.split('=')[1]
        localStorage.setItem("etube", href);
      }
  
      email = localStorage.getItem("etube");

      // await getDocs(collection(db, "etube"))
      // .then((querySnapshot) => {
  
      //   const etubeData = querySnapshot.docs
      //     .map((doc) => ({ ...doc.data(), id: doc.id }));
  
      //     etubeData.forEach(element => {
      //       if (element['email'] == email)
      //       etubekey = element['key'];
      //     });
      // })
  
      //getauthapi();
      
      if (!email || email == '')
      setHasKey(2)
    }
    fetchData();


  }, []);

  const logingoogle = async () => {
    window.location.href = api.BASE_URL+'/../auth';
  }

  return (
    <div className="flex h-[calc(100%-56px)]">

      {(hasKey == 2) && (
        <div className="popup">
          <div className="popupemail">
            <br></br>
          Sign in with your Google account! 
          <br></br>
             <button className="btngoogle" onClick={logingoogle}>Login with Goole</button>
            </div>
        </div>
      )}

      <LeftNav />
      {!isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-black">
          <OfflineComponent />
        </div>
      )}
      {isOnline && (
        <div className="grow w-[calc(100%-240px)] h-full overflow-y-auto bg-white dark:bg-black">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-5">
            {/* When loading is true means data is not fetched yet meanwhile that time show Shimmer UI 
             when data fetching is done means loading is false and that means we get searchResults then show the data in the dom */}
            {loading
              ? Array(24)
                .fill("")
                .map((e, index) => {
                  return <ShimmerVideoCard key={index} />;
                })
              : searchResults.map((item, index) => {
                if (item.type !== "video") return false;
                return <VideoCard key={index} video={item?.video} />;
                // i allways prefered to pass unique key that i got from the api and that is standered way also but for this api some unique key like videoId present twice and more in the searchResults, i don't khow why. So, forcefully i chose map index :(
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Feed;
